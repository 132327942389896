import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Logo from '../../assets/images/Padimedical.png';
import { useSelector } from 'react-redux';
import './para.css'
import {
    Document,
    PDFDownloadLink,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
    PDFViewer,

} from "@react-pdf/renderer";
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'
import ActionBoutonView from '../CommonComponents/RessourcesDisplay/ActionButtonView';
import AssignDoctor from '../CommonComponents/AssignDoctor/AssignDoctor';
import GeneratePDF from './PDFGenerator/ReportPDF';


const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        paddingHorizontal: 40,
        paddingVertical: 40,
    },
    imageArea: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: '50px',
        marginTop: 20,
    },
    imagePDF: {
        width: '200px',
        marginTop: 20,
    },
    text: {
        fontSize: 12,
        lineHeight: '1.5px',
        fontFamily: 'Helvetica',
    },
    boxContainer: {
        marginTop: 50
    },
    textContentArea: {
        marginVertical: '35px',
    },
    infoBox: {
        width: '100%',
        border: '1px solid black',
        flexDirection: 'row'
    },
    subBox: {
        width: '50%',
        height: '100%',
        padding: 10,
    },
    signature: {
        width: '150px',
        marginBottom: 20,
    },
    heading: {
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: 3,
    },
    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: {}

});


const FormateDataForTable = (data) => {
    if (!data)
        return null
    let parseData = JSON.parse(data);
    if (parseData?.data?.length > 1) {
        let temptableData = parseData.data.map((element) => {
            let rowData = parseData?.headers?.map((header) => {
                return element[header] || ''
            })
            return rowData
        })
        return { headers: parseData.headers, data: temptableData }
    }
    return null;
}

const GetTableHTML = ({ data }) => {
    const tableData = useMemo(() => {
        return FormateDataForTable(data);
    }, [data])

    if (!tableData)
        return null
    return (
        <div className='d-flex justify-content-center'>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        {tableData.headers.map((text) => <th scope="col">{text}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {tableData.data.map((element) => { return <tr>{element.map((text) => <td>{text}</td>)}</tr> })}
                </tbody>
            </table>
        </div>
    )


}



const AddendumView = ({ signature, text, addendum_at, addendumby, image, index, practicing_no, table }) => {
    const Text = useMemo(() => {
        let tempText = text;
        let splitBy;
        try {
            if (tempText.indexOf('IMPRESSION:') != -1) splitBy = 'IMPRESSION:';
            else if (tempText.indexOf('Impression:') != -1) splitBy = 'Impression:';
            else if (tempText.indexOf('impression:') != -1) splitBy = 'impression:';
            tempText = tempText?.split(splitBy);
            return { text1: tempText[0], text2: splitBy + tempText[1] }
        }
        catch {
            return { text1: null, text2: null }
        }

    }, [text])
    return (
        <div>
            <div style={{ marginTop: 20, }}>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>ADDENDUM v{index}</p>
                <br /><br />
                <div style={{ maxWidth: 'max-content', lineHeight: '16px' }}>
                    {Text.text1 && Text.text2 && (image || table) ?
                        <>
                            <p dangerouslySetInnerHTML={{ __html: Text?.text1?.replaceAll('\n', '<br/>') }}></p>
                            {image && <><img style={{ width: '250px', margin: 'auto', display: 'block' }} src={image} />
                                <br /><br /></>}
                            <GetTableHTML data={table} />
                            <p dangerouslySetInnerHTML={{ __html: Text?.text2?.replaceAll('\n', '<br/>') }}></p>
                        </> :
                        <>
                            <GetTableHTML data={table} />
                            <p dangerouslySetInnerHTML={{ __html: text?.replaceAll('\n', '<br/>') }}></p>
                        </>
                    }
                </div>
            </div>
            <br />
            {signature ? <img style={{ width: '200px' }} src={signature} /> : null}
            <br />
            <br />
            <p>Computer generated, no signature required.</p>
            {addendumby ? <p>Addendum of this report is done on {addendum_at} by {addendumby} ({practicing_no})</p> : null}
        </div >
    )
}



const formatDate = (str) => {
    if (!str)
        return null
    return str?.slice(6, 8) + '/' + str?.slice(4, 6) + '/' + str?.slice(0, 4)
}

const ViewReport = () => {
    const { id } = useParams();
    const { study_date, StudyInstanceUID } = useLocation();
    const [data, setData] = useState({
        patient_name: null, patient_id: null, tag: null, study_type: null, text: null, createdAt: null, study_date: null, created_by: null, signature: null, image: null,
        addendumby: null, addendum_at: null,
        practicing_no: null,
        accesor: null,
        table: null,
    });
    const [text, setText] = useState({ text1: null, text2: null })
    const [available, setAvailable] = useState(true);
    const [addendum, setAddendun] = useState([]);


    const roles = useSelector(state => state.PadiMedical.roles)

    useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('#main').style.minWidth = '600px';
        document.querySelector('#main').style.maxWidth = 'max-content';
        fetchReport();
        return () => {
            let element = document.querySelector('#main');
            if (element)
                element.style.width = 'none';
        }
    }, []);


    const fetchReport = () => {
        fetch("/api/patient-report", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify({
                studyid: id,
            })
        })
            .then(res => {
                if (res.status == 200)
                    return res.json()
                else
                    throw "Bad req"
            })
            .then(res => {
                setData({
                    patient_name: res?.report_data?.patient_name || null,
                    tag: res?.report_data?.tag || null,
                    study_type: res?.report_data?.study_type || null,
                    text: res?.report_data?.text || null,
                    createdAt: res?.report_data?.createdAt ? new Date(res.report_data.createdAt).getDate().toString().padStart(2, '0') + '/' + (new Date(res.report_data.createdAt).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(res.report_data.createdAt).getFullYear() : '',
                    patient_id: res?.report_data.patient_id,
                    study_date: res?.report_data?.study_date,
                    created_by: res?.report_data.created_by,
                    signature: res?.report_data?.signature,
                    image: res?.report_data?.image,
                    addendum_at: res?.report_data?.addendum_at,
                    addendumby: res?.report_data?.addendumby,
                    practicing_no: res?.report_data?.practicing_no,
                    accesor: res?.report_data?.accesor,
                    table: res?.report_data?.table,
                });
                let tempText = res?.report_data?.text;
                let splitBy;
                try {
                    if (tempText.indexOf('IMPRESSION:') != -1) {
                        splitBy = 'IMPRESSION:';
                    }
                    else if (tempText.indexOf('Impression:') != -1) {
                        splitBy = 'Impression:'
                    }
                    else if (tempText.indexOf('impression:') != -1) {
                        splitBy = 'impression:'
                    }

                    tempText = tempText?.split(splitBy);
                    setText({ text1: tempText[0], text2: splitBy + tempText[1] })
                }
                catch {
                    setText({ text1: null, text2: null })
                }
                setAddendun(res?.all_addendum || [])

            }).catch(() => {
                setAvailable(false);
            })
    }
    if (!available) {
        return (
            <div style={{ minHeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="mb-5">
                <h2>Report not available yet !!</h2>
            </div>
        )
    }





    const GeneratePDFStart = () => {
        let { text, image, table, signature, created_by, createdAt, practicing_no, addendumby, addendum_at, } = addendum[0] || data

        let formateTable = FormateDataForTable(table)
        let tempTableData = []
        if (formateTable) {
            tempTableData.push(formateTable.headers.map(element => { return { bold: true, text: element } }));
            formateTable.data.forEach(arr => { tempTableData.push(arr) });
        }
        else {
            tempTableData = [[]]
        }
        let splitBy;
        let tempText = text;
        let text1 = null;
        let text2 = null;
        try {
            if (tempText.indexOf('IMPRESSION:') !== -1) {
                splitBy = 'IMPRESSION:';
            }
            else if (tempText.indexOf('Impression:') !== -1) {
                splitBy = 'Impression:'
            }
            else if (tempText.indexOf('impression:') !== -1) {
                splitBy = 'impression:'
            }

            tempText = tempText?.split(splitBy);
            text1 = tempText[0];
            text2 = splitBy + tempText[1];
        }
        catch { }
        GeneratePDF(Logo,
            data.patient_name,
            data.patient_id,
            data.study_type,
            formatDate(data.study_date),
            text1,
            text2,
            image,
            tempTableData,
            text,
            signature,
            created_by || addendumby,
            addendum_at || formatDate(createdAt),
            practicing_no,
        )
    }

    return (
        <>

            <>
                <div className='d-flex justify-content-evenly align-items-center'>
                    <Button
                        className='button-dropdown button-dropdown-orange  btn btn-button-dropdown-orange'
                        style={{ width: '9rem', justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: 'rgb(76, 188, 210)' }}
                        onClick={GeneratePDFStart}> Download </Button>
                    <br />
                    <ActionBoutonView
                        //tukar svr
                        osimis_link={'https://upm.padimedical.com/osimis-viewer/app/index.html?study=' + id}
                        OhifLink={'/viewer-ohif/viewer/' + StudyInstanceUID}
                        radiant={'radiant://?n=pstv&v=0020000D&v=%22' + StudyInstanceUID}
                        osirix={'osirix://?methodName=downloadURL&URL=https://upm.padimedical.com/studies/' + id + '/archive'}
                        downloadzip={'https://upm.padimedical.com/studies/' + id + '/archive'}
                    />
                    {roles.can_assign_doctors ?
                        <AssignDoctor
                            study_id={id}
                            patient_name={data.patient_name}
                            patient_id={data.patient_id}
                            accesor={data.accesor}
                            study_type={data.study_type}
                            study_date={data.study_date}
                            className='button-dropdown button-dropdown-blue w-10  btn' /> : null}
                </div>
                <div id='area' style={{ lineHeight: '14px', fontSize: 14, color: 'black' }} className="mb-6 ">
                    <br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img height={50} src={Logo} />
                    </div>
                    <br />
                    <br />
                    <div style={{ marginTop: 20, }}>
                        <div className='box'>
                            <div className='col-6'>
                                <p>Patient Name : {data.patient_name}</p>
                                <p>Patient ID :  {data.patient_id}</p>
                            </div>
                            <div className='col-6'>
                                <p>Study Modality : {data.study_type}</p>
                                <p>Study Date : {data?.study_date?.slice(6, 8) + '/' + data?.study_date?.slice(4, 6) + '/' + data?.study_date?.slice(0, 4)}</p>
                            </div>
                        </div>
                        {addendum.map((element, index) => (<AddendumView {...element} index={addendum.length - index} />))}
                        <br /><br />
                        {addendum.length !== 0 ? <><p style={{ textAlign: 'center', fontWeight: 'bold' }}>ORIGINAL REPORT</p><br /> </> : null}
                        <div style={{ maxWidth: 550, lineHeight: '16px' }}>
                            {text.text1 && text.text2 && (data.image || data.table) ?
                                <>
                                    <p dangerouslySetInnerHTML={{ __html: text?.text1?.replaceAll('\n', '<br/>') }}></p>
                                    {data.image && <><img style={{ width: '250px', margin: 'auto', display: 'block' }} src={data.image} /><br /><br /></>}
                                    <GetTableHTML data={data.table} />
                                    <p dangerouslySetInnerHTML={{ __html: text?.text2?.replaceAll('\n', '<br/>') }}></p>
                                </> :
                                <p dangerouslySetInnerHTML={{ __html: data?.text?.replaceAll('\n', '<br/>') }}></p>}

                        </div>

                    </div>
                    <br />
                    {data.signature ? <img style={{ width: '200px' }} src={data.signature} /> : null}
                    <br />
                    <br />
                    <p>Computer generated, no signature required.</p>
                    <p>This report is created on {data.createdAt} transcribed by {data.created_by} ({data.practicing_no})</p>

                </div >

            </>
        </>
    )
}

export default ViewReport;
