import { assert, async } from "pdfjs-dist";
import pdfMake from "pdfmake/build/pdfmake";
import vfs from "./fonts/vfs_fonts";
import FotterImage from '../../../assets/images/fotter.png';

pdfMake.vfs = vfs;

pdfMake.fonts = {
    Roboto: {
        normal: "NimbusSanL-Reg.otf",
        bold: "NimbusSanL-Bol.otf",
        italics: "NimbusSanL-RegIta.otf",
        bolditalics: "NimbusSanL-BolIta.otf"
    }
};

const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}


function getReportFormate(text1, text2, text, image, tableData, signature, createdAt, created_by, practicing_no, addendum) {
    let array = []
    console.log('tex1 & text2', text1, text2);
    if (text1 && text2) {
        array.push({ text: text1 })
        if (image) {
            array.push({
                margin: [0, 50,],
                image: image,
                alignment: 'center',
                fit: [595, 250],
            })
        }
        if (tableData) {
            array.push({
                margin: [0, 10, 0, 30],
                columns: [
                    { width: '*', text: '' },
                    {
                        width: 'auto',
                        table: {
                            headerRows: 1,
                            body: tableData,
                        },
                        layout: {
                            paddingLeft: (i, node) => 10,
                            paddingRight: (i, node) => 10,
                            paddingTop: (i, node) => 5,
                            paddingBottom: (i, node) => 5
                        },

                    },
                    { width: '*', text: '' },
                ]
            },)
        }

        array.push({ text: text2 })
    } else {
        if (tableData) {
            array.push({
                columns: [
                    { width: '*', text: '' },
                    {
                        width: 'auto',
                        magin: [0, 0, 0, 20],
                        table: {
                            body: tableData,
                        },
                        layout: {
                            paddingLeft: (i, node) => 10,
                            paddingRight: (i, node) => 10,
                            paddingTop: (i, node) => 5,
                            paddingBottom: (i, node) => 5
                        }
                    },
                    { width: '*', text: '' },
                ]
            },)
        }
        array.push({ text: text })

    }

    if (signature) {
        array.push({
            image: signature,
            width: 150
        })
    }

    array.push({
        text: 'Computer generated, no signature required.',
        margin: [0, 20, 0, 0]
    })

    if (addendum) {
        array.push({
            text: `This report is created on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
            margin: [0, 0, 0, 100]
        })
    } else {
        array.push({
            text: `Addendum of this report is done on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
            margin: [0, 0, 0, 100]
        })
    }


    return array
}





async function GeneratePDF(logo, patient_name, patient_id, study_type, study_date, text1, text2, image, table, text, signature,
    addendumby, addendum_at, practicing_no) {
    var dd = {
        content: [
            {
                image: await getBase64ImageFromURL(logo),
                fit: [400, 50],
                alignment: 'center',
                margin: [0, 15, 0, 0],
            },
            {
                style: 'tableExample',
                width: '100%',
                margin: [0, 40, 0, 60],
                lineHeight: 1,
                table: {
                    headerRows: 1,
                    widths: ['*', '*',],
                    body: [
                        [
                            {
                                text: ` Patient Name :   ${patient_name.replaceAll('  ', ' ').match(/.{1,20}/g).join('\n\n-')} \n\n  Patient ID :   ${patient_id}`
                            },
                            {
                                text: ` Study Modality :  ${study_type.match(/.{1,23}/g).join('\n\n-')}   \n\n Study Date :  ${study_date}`
                            },
                        ],


                    ]
                },
                layout: {
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? '' : 'blue';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? '' : '#fff';
                    },
                    paddingLeft: function (i, node) { return 10; },
                    paddingRight: function (i, node) { return 10; },
                    paddingTop: function (i, node) { return 10; },
                    paddingBottom: function (i, node) { return 10; },
                },
            },

            ...getReportFormate(text1, text2, text, image, table,
                signature,
                addendum_at,
                addendumby,
                practicing_no
            ),
        ],
        // footer: [{
        //     image: await getBase64ImageFromURL(FotterImage),
        //     fit: [595, 39.5],
        //     alignment: 'center',
        // }],
        defaultStyle: {
            alignment: 'justify',
            fontSize: 12,
            fontFamily: 'NimbusSans',
            color: '#000',
            lineHeight: 1.5,
        },
    }

    const pdfDocGenerator = pdfMake.createPdf(dd);
    pdfDocGenerator.download(`${patient_name}.pdf`);


}


export default GeneratePDF;
