import React, { useEffect, useState, Suspense } from 'react';
import Logo from '../../assets/images/Padimedical.png';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CHEST_X_RAY, MAMMOGRAM, ABDOMEN_PELVIS_MALE, ABDOME_PELVIS_FEMALE } from '../../report-template/index'
import ActionBoutonView from '../CommonComponents/RessourcesDisplay/ActionButtonView';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import socket from '../../socket/socket';
import './typing-effect.css'
import Toggle from 'react-toggle';
const DynamicTable = React.lazy(() => import('./DynamicTable/DynamicTable'));
const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

const Typing = ({ id }) => {
    const [text, setText] = useState(null);
    let typingTime = new Date();
    useEffect(() => {
        socket.on(id, (payload) => {
            if (payload) {
                setText(payload.map((element) => {
                    return '  Dr ' + element[0].toUpperCase() + element.slice(1);
                }))
            } else {
                setText(null);
            }
            typingTime = new Date();
        })
        let interval = setInterval(() => {
            if (((new Date().getTime() - typingTime.getTime()) / 1000) > 1) {
                setText(null);
            }
        }, [1000])
        return () => {
            clearInterval(interval);
            socket.off(id)
        }
    }, [])
    return <h6 style={{ textAlign: 'right', visibility: text ? "visible" : 'hidden' }} className='text-primary '>{text} is typing <span className='dot1'>.</span><span className='dot2'>.</span><span className='dot3'>.</span></h6>;
}

const CreateReport = () => {
    const [editing, setEditing] = useState(false)
    const { pid, pname, study_type, study_date, StudyInstanceUID, accessor } = useLocation();
    const [isfinalize, setFinalize] = useState(false);
    const [data, setData] = useState({
        patient_name: pname,
        tag: '',
        study_type: study_type,
        study_date: study_date,
        patient_id: null,
        text: null,
        signature: null,
        image: null,
        table: null,
    });
    const { id } = useParams();
    const roles = useSelector(state => state.PadiMedical.roles)
    const [can_view, setView] = useState(false);
    const [tags, setTags] = React.useState([]);
    const [user, setUser] = useState([]);
    const [showtable, setShowTable] = useState(false);
    let currentClick = null;




    useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('#main').style.width = 'max-content';
        fetchReport();
        fetch('/api/users/radiologist')
            .then(res => res.json())
            .then(res => setUser(res))
            .catch(err => console.log('err'))
        return () => {
            stop_type();
            let element = document.querySelector('#main');
            if (element)
                element.style.width = 'none';
        }
    }, [])

    const fetchReport = () => {
        fetch(`/api/request-report/${id}/is_finalize`)
            .then(res => res.json())
            .then(res => setFinalize(res));

        fetch("/api/admin-report", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify({
                studyid: id,
            })
        })
            .then(res => {
                if (res.status == 200)
                    return res.json()
                else {

                    throw "Bad req"
                }

            })
            .then(res => {
                setData({
                    patient_name: res?.patient_name || pname,
                    tag: res?.tag || '',
                    study_type: res?.study_type || study_type,
                    study_date: res?.study_date || study_date,
                    patient_id: res?.patient_id || pid,
                    text: res?.text,
                    signature: res?.signature,
                    image: res?.image,
                    table: res?.table
                });
                setTags(res?.doctors == '' ? [] : res?.doctors)

                setEditing(true);
                if (res.study_date)
                    setView(true)
            }).catch(err => {
                setData({ ...data, text: '' })
            })
    }

    const validate = () => {
        let data1 = [data.patient_name, data.study_type]
        let key = ['patient name', 'study type']
        let error = false;
        data1.map((element, index) => {
            if (element == '' || element == null) {
                error = true;
                toast.error(`${key[index]} field is required`);
            }
        })

        if (data.text == '' || data.text == null) {
            toast.error('report is required !!')
            error = true
        }

        return error
    }

    const handleChange = (e) => {
        setData(prev => { return { ...prev, [e.target.name]: e.target.value } })
        typing();
    }


    const handleDraft = (table_data) => {
        if (!validate()) {
            fetch("/api/create-report-draft", {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: "POST",
                body: JSON.stringify({
                    patient_name: data.patient_name,
                    studyid: id,
                    text: data?.text || '',
                    ...data,
                    patient_id: data.patient_id || pid,
                    doctors: tags,
                    StudyInstanceUID: StudyInstanceUID,
                    accesor: accessor,
                    table: table_data,
                })
            }).then((res) => {
                if (res.status == 201) {
                    fetchReport();
                    toast.success('draft report saved !!');
                    setView(true);
                } else {
                    toast.error('something went wrong !!');
                }
            })
        }
    }

    const handleFinalize = (table_data) => {
        if (!validate()) {
            fetch("/api/create-report-final", {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: "POST",
                body: JSON.stringify({
                    patient_name: data.patient_name,
                    studyid: id,
                    text: data?.text || '',
                    tag: '',
                    created_by: roles?.firstname || roles.username,
                    ...data,
                    patient_id: data.patient_id || pid,
                    practicing_no: roles?.practicing_no,
                    doctors: tags || [],
                    StudyInstanceUID: StudyInstanceUID,
                    accesor: accessor,
                    table: table_data,
                })
            }).then((res) => {
                if (res.status == 201) {
                    fetchReport();
                    toast.success('report saved !!');
                    setView(true);
                } else {
                    toast.error('something went wrong !!');
                }
            })
        }

    }

    const Addendum = (table_data) => {
        if (!validate()) {
            fetch("/api/create-report-final", {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: "POST",
                body: JSON.stringify({
                    patient_name: data.patient_name,
                    studyid: id,
                    text: data?.text || '',
                    tag: '',
                    addendumby: roles?.firstname || roles.username,
                    addendum_at: getTodayDate(),
                    ...data,
                    patient_id: data.patient_id || pid,
                    practicing_no: roles?.practicing_no,
                    doctors: tags || [],
                    StudyInstanceUID: StudyInstanceUID,
                    accesor: accessor,
                    table: table_data,
                })
            }).then((res) => {
                if (res.status == 201) {
                    fetchReport();
                    toast.success('report saved !!');
                    setView(true);
                } else {
                    toast.error('something went wrong !!');
                }
            })
        }

    }

    async function encodeImageFileAsURL(element) {
        var file = element.target.files[0];
        console.log(data)
        var reader = new FileReader();

        reader.onloadend = function () {
            setData({ ...data, signature: reader.result })

        }
        reader.readAsDataURL(file);
    }

    async function encodePDFImageFileAsURL(element) {
        var file = element.target.files[0];
        console.log(data)
        var reader = new FileReader();

        reader.onloadend = function () {
            setData({ ...data, image: reader.result })

        }
        reader.readAsDataURL(file);
    }

    const deleteReport = () => {
        fetch("/api/admin-report", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "DELETE",
            body: JSON.stringify({
                studyid: id,
            })
        }).then(() => {
            setData({
                patient_name: pname,
                tag: '',
                study_type: study_type,
                study_date: study_date,
                patient_id: null,
                text: null,
                signature: null,
                image: null,
            });
            fetchReport();
            setView(false);
            toast.success('report deleted !!')
        })
    }

    const setTemplate = (e) => {
        // eslint-disable-next-line default-case
        switch (e.target.value) {
            case 'ABDOMEN PELVIS MALE':
                setData({ ...data, text: ABDOMEN_PELVIS_MALE })
                break;
            case 'ABDOME PELVIS FEMALE':
                setData({ ...data, text: ABDOME_PELVIS_FEMALE })
                break;
            case 'CHEST X RAY':
                setData({ ...data, text: CHEST_X_RAY })
                break;
            case 'MAMMOGRAM':
                setData({ ...data, text: MAMMOGRAM })
        }
    }


    const typing = () => {
        socket.emit('typing', { study_id: id })
    }

    const stop_type = () => {
        socket.emit('stop-typing', { study_id: id })

    }

    const getData = (data) => {
        switch (currentClick) {
            case "Draft":
                handleDraft(JSON.stringify(data));
                break
            case "Finalize":
                handleFinalize(JSON.stringify(data));
                break;
            case "Addendum":
                Addendum(JSON.stringify(data));
                break;
            default:
                return
        }
    }

    const Click = (e, type) => {
        e.preventDefault();
        switch (type) {
            case "Draft":
                currentClick = "Draft"
                break
            case "Finalize":
                currentClick = "Finalize"
                break;
            case "Addendum":
                currentClick = "Addendum"
                break;
            default:
                return
        }
        let btn = document.querySelectorAll('.dynamic-table-wrapper div button');
        if (btn) {
            btn = btn[2]
            btn.click();
        } else {
            switch (type) {
                case "Draft":
                    handleDraft(data.table);
                    break
                case "Finalize":
                    handleFinalize(data.table);
                    break;
                case "Addendum":
                    Addendum(data.table);
                    break;
                default:
                    return
            }
        }
        console.log('btn=>', btn)
        return;

    }


    return (<>
        <div className="mb-5 create-report">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img height={50} src={Logo} />
            </div>
            <form style={{ maxWidth: 1400, marginTop: 50, minWidth: 800 }} className='container'>
                <div className='row d-flex'>
                    <div className='col-6'>
                        <label for="pname" class="form-label">Patient's Name</label>
                        <input required value={data.patient_name} name='patient_name' onChange={handleChange} id='pname' className='form-control' />
                    </div>
                    <div className='col-6'>
                        <label for="pid" class="form-label">Patient's ID</label>
                        <input value={data.patient_id || pid} readOnly id='pid' className='form-control' />
                    </div>
                </div>
                <br />
                <div className='row d-flex'>
                    <div className='col-6'>
                        <label for="stype" class="form-label">Study Type</label>
                        <input id='stype' name='study_type' value={data.study_type} onChange={handleChange} className='form-control' required />
                    </div>

                    <div className='col-6'>
                        <label for="sdata" class="form-label">Study Date</label>
                        <input id='sdata' name='study_date' value={data.study_date || study_date} className='form-control' readOnly />
                    </div>
                </div>

                <div className='row d-flex'>
                    <div className='col-6'>
                        <label for="stype" class="form-label">Upload</label>
                        <input id='stype' type='file' name='study_type' onChange={encodeImageFileAsURL} className='form-control' />
                    </div>

                    <div className='col-6'>
                        <label for="stype" class="form-label">Select Template</label>
                        <input list="templates" onChange={setTemplate} class="form-control col-sm-6 custom-select custom-select-sm" name="template" id="template" />
                        <datalist id='templates' >
                            <option value={'CHEST X RAY'}></option>
                            <option value={'MAMMOGRAM'}></option>
                            <option value={'ABDOMEN PELVIS MALE'}></option>
                            <option value={'ABDOME PELVIS FEMALE'}></option>
                        </datalist>
                    </div>

                </div>
                <div className='row d-flex'>
                    {roles?.report_with_pdf ?
                        <div className='col-6'>
                            <label for="report_image" class="form-label">Upload Report Image</label>
                            <input id='report_image' type='file' name='report_image' onChange={encodePDFImageFileAsURL} className='form-control' />
                        </div>
                        : null}

                    <div style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'left' }} className='col-6'>
                        <ActionBoutonView
                            //tukar svr
                            osimis_link={'https://upm.padimedical.com/osimis-viewer/app/index.html?study=' + id}
                            OhifLink={'/viewer-ohif/viewer/' + StudyInstanceUID}
                            radiant={'radiant://?n=pstv&v=0020000D&v=%22' + StudyInstanceUID}
                            osirix={'osirix://?methodName=downloadURL&URL=https://upm.padimedical.com/studies/' + id + '/archive'}
                            downloadzip={'https://upm.padimedical.com/studies/' + id + '/archive'}
                        />
                    </div>
                </div>
                <br />
                {roles.can_assign_doctors ?
                    <div className='row d-flex'>
                        <div className='col-6'>
                            <label for="stype" class="form-label">Assign Doctors</label>
                            <Typeahead
                                multiple
                                onChange={(selected) => { setTags(selected) }}
                                options={user.map((element) => `Dr. ${element.firstname}  ${element.lastname} (${element.username})`)}
                                selected={tags}
                                id='doctors'

                            />

                        </div>
                    </div> : null
                }
                <br />
                <br />
                {roles.can_add_table &&
                    <>
                        <div className='d-flex justify-content-start align-items-center '>
                            <span className='h5'>Show Table</span>
                            <Toggle className='ms-2' checked={showtable} onChange={value => setShowTable(!showtable)} />
                        </div>
                        {
                            <div style={{ display: `${showtable ? 'block' : 'none'}` }}>
                                <Suspense fallback={null}>
                                    <DynamicTable data={data.table} getData={getData} />
                                </Suspense>
                            </div>

                        }
                    </>
                }

                <div className='row d-flex'>
                    <div className='col-12'>
                        <Typing id={id} />
                        <textarea
                            className='form-control'
                            cols='12'
                            rows='20'
                            name='text'
                            value={data?.text}
                            onChange={handleChange}
                            onBlur={stop_type}
                        />
                    </div>
                </div>
                <br />
                <div className='d-flex'>

                    {editing ?
                        <Button disabled={!roles.edit_patient_report || isfinalize} type='submit' onClick={(e) => Click(e, "Draft")} className='btn m-1 '>Save</Button> :
                        <Button disabled={!roles.create_patient_report || isfinalize} type='submit' onClick={(e) => Click(e, "Draft")} className='btn m-1 '>Save</Button>
                    }

                    <Link
                        to={{ pathname: `/report/view/${id}`, study_date: study_date, StudyInstanceUID }}>
                        <Button disabled={!isfinalize} className='btn m-1'>View</Button>
                    </Link>



                    <div className='ms-auto'>
                        {roles.delete_report ?
                            <Button onClick={deleteReport} className='btn m-1 btn-danger'>Delete</Button>
                            : null}

                        {roles?.addendun ?
                            <Button disabled={!isfinalize} onClick={(e) => Click(e, "Addendum")} className='btn m-1 '>Addendum</Button>
                            : null}

                        {editing ?
                            <Button disabled={!(roles.edit_patient_report && roles.can_finalize_report) || isfinalize} onClick={(e) => Click(e, "Finalize")} className='btn m-1'>Finalize</Button> :
                            <Button disabled={!(roles.create_patient_report && roles.can_finalize_report) || isfinalize} onClick={(e) => Click(e, "Finalize")} className='btn m-1'>Finalize</Button>
                        }


                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default CreateReport;
