import React, { useState } from 'react'
import apis from '../../services/apis'
import Lock from '@material-ui/icons/Lock';
import padilogo from '../../assets/images/padi-logo-transparent.png';


export const ExternalAccess = ({ params }) => {
  const { viewer, StudyInstanceId } = params
  const [password, setPassword] = useState("")
  // console.log('params', params)
  async function submit() {
    apis.authentication.loginExternal(StudyInstanceId, password)
      .then((req) => {
        if (req.ok) {
          let redirect
          switch (viewer) {
            case 'stone':
              redirect = `${window.location.protocol}//${window.location.host}/viewer-stone/index.html?study=${StudyInstanceId}`
              break
            case 'ohif':
              redirect = `${window.location.protocol}//${window.location.host}/viewer-ohif/viewer/${StudyInstanceId}`
              break
            case 'osimis':                            //tukar svr
              redirect = `${window.location.protocol}//upm.padimedical.com/osimis-viewer/app/index.html?study=${StudyInstanceId}` //this is orthanc id 
              break;
            case 'download':
              redirect = `${window.location.protocol}//upm.padimedical.com/studies/${StudyInstanceId}/archive`;
              break
            case 'view':
              redirect = `${window.location.protocol}//upm.padimedical.com/osimis-viewer/app/index.html?study=${StudyInstanceId}`
              break
            default:
              alert('Wrong viewer passed to URL')
              return
          }
          window.location.replace(redirect)
        } else {
          alert('Wrong password')
        }
      })
  }

  return (
    <div className='vertical-center authentification'>
      <div className='text-center' id='login'>
        {/* <div className='shadow block-title block block-400'>
          <div className='row'>
            <div className='col'>
              PadiMedicalv4.1
            </div>
          </div>
        </div> */}
        <img src={padilogo} id='logo-login' height="180" text-align="center"></img>
        <div className='block-content block block-400'>
          <h3 className='text-center'>Enter your access code</h3>
          <br />
          <form id='login-form'>
            <fieldset>
              <label><Lock /></label>
              <input type='password' placeholder='enter your access code' name='password' value={password} onChange={(e) => setPassword(e.target.value)} required />
            </fieldset>
            <br />

            <button name='connexion' type='button' className='login-btn' onClick={submit}>{viewer === 'download' ? 'Download' : 'View'}</button>

          </form>
        </div>
      </div>
    </div>
  )
}
