import React, { Component } from 'react'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import ToolsPanel from './ToolsPanel'
import { Image, Nav, Navbar } from 'react-bootstrap'

import { CSSTransition, TransitionGroup } from "react-transition-group";

import Footer from './Footer'
import Query from '../Query/Components/Query'
import AutoQueryRoot from '../AutoQuery/Connected_Component/AutoQueryRoot'
import RobotView from '../AutoQuery/Connected_Component/RobotView'
import AdminRootPanel from '../Admin/AdminRootPanel'
import ImportRootPanel from '../Import/ImportRootPanel'
import ContentRootPanel from '../OrthancContent/ContentRootPanel'
import ExportPanel from '../Export/ExportPanel'
import AnonRootPanel from '../Anonymize/AnonRootPanel'
import Delete from '../Delete/Delete'
import CDBurner from './../CDBurner/CDBurner'
import MyDicom from '../MyDicom/MyDicom'
import DicomRouterPanel from '../Dicom Router/DicomRouterPanel'
import { CreateReportView } from '../CreateReport/CreateReportView'

//added by rishabh 12-6-2022
import CreateReport from '../Reports/CreateReport';
import ViewReport from '../Reports/ViewReport';
import RequestReport from '../Reports/RequestReport';

//added by rishabh 28-6-2022
import RequestAdvanceImagin from '../Reports/RequestAdvanceImagin';

//request report 
import RequestReportList from '../RequestReportList/RequestReportList';
import Addendun from '../Reports/Addendun';

//added by rishabh 29/7/22
import RequestAdvanceImaginList from '../RequestAdvImagin/RequestAdvanceImaginList';
import DoctorCaseList from '../CaseList/DoctorCaseList';
import AdminCaseList from '../CaseList/AdminCaseList';
import image from '../../assets/images/logo.png';
import socket from '../../socket/socket';
const RESPONSIVE_LIMIT = 992;
export default class NavBar extends Component {

    state = {
        currentTabSelect: null,
        opened: false
    }


    componentDidMount = async () => {
        this.setState({
            navbar: document.documentElement.clientWidth < RESPONSIVE_LIMIT ? 'responsive' : 'classique',
            currentTabSelect: 'content'
        })

        window.addEventListener('resize', () => {
            const size = document.documentElement.clientWidth
            this.setState({ navbar: size < RESPONSIVE_LIMIT ? 'responsive' : 'classique' })
        });

        socket.emit('setname', { username: this.props.username }) //concurrent tukar
    }


    getLinkClass = (tabName) => {
        if (this.state.currentTabSelect === tabName) return 'nav-link active'
        else return 'nav-link'
    }

    selectTabHandler = (event) => {
        let target = event.target
        this.setState({
            currentTabSelect: target.name
        })
    }

    render = () => {
        return (
            <div className='app'>
                <Navbar id="navbar"
                    expand="md"
                    variant='dark'>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <div className="otjs-navbar-border" hidden={!this.state.opened}></div>
                            <Link className={this.getLinkClass('content')} onClick={this.selectTabHandler}
                                name='content' to='/padimedical-content' hidden={!this.props.roles.content}>
                                Patient
                            </Link>
                            <Link className={this.getLinkClass('import')} onClick={this.selectTabHandler} name='import'
                                to='/import' hidden={!this.props.roles.import}>
                                Import
                            </Link>
                            <Link className={this.getLinkClass('query')} onClick={this.selectTabHandler} name='query'
                                to='/query' hidden={!this.props.roles.query}>
                                Query
                            </Link>
                            <Link className={this.getLinkClass('auto-query')} onClick={this.selectTabHandler}
                                name='auto-query' to='/auto-query'
                                hidden={!this.props.roles.auto_query}>
                                Auto-Retrieve
                            </Link>
                            <Link className={this.getLinkClass('burner')} onClick={this.selectTabHandler} name='burner'
                                to='/cd-burner' hidden={!this.props.roles.cd_burner}>
                                CD-burner
                            </Link>
                            <Link className={this.getLinkClass('mydicom')} onClick={this.selectTabHandler}
                                name='mydicom' to='/mydicom'>
                                MyDicom
                            </Link>
                            <Link className={this.getLinkClass('create-report')} onClick={this.selectTabHandler}
                                name='create-report' to='/create-report' hidden={!this.props.roles.create_report}>
                                Create Report
                            </Link>
                            <Link className={this.getLinkClass('my-case-list')} onClick={this.selectTabHandler}
                                name='my-case-list' to='/my-case-list' hidden={!this.props.roles.can_view_assign_caselist}>
                                My Case List
                            </Link>
                            <Link className={this.getLinkClass('admin-case-list')} onClick={this.selectTabHandler}
                                name='admin-case-list' to='/admin-case-list' hidden={!this.props.roles.can_view_admin_caselist}>
                                All Case List
                            </Link>
                            <Link className={this.getLinkClass('request-report-list')} onClick={this.selectTabHandler}
                                name='request-report-list' to='/request-report-list' hidden={!this.props.roles.view_request_report}>
                                Request Report List
                            </Link>
                            <Link className={this.getLinkClass('advance-report-imaging')} onClick={this.selectTabHandler}
                                name='advance-report-imaging' to='/advance-report-imagin' hidden={!this.props.roles.view_imagin}>
                                Advance Imaging List
                            </Link>
                            <Link className={this.getLinkClass('dicom-router')} onClick={this.selectTabHandler}
                                name='dicom-router' to='/dicom-router'
                                hidden={!this.props.roles.autorouting}>
                                Dicom-Router
                            </Link>
                            <Link className={this.getLinkClass('administration')} onClick={this.selectTabHandler}
                                name='administration' to='/administration'
                                hidden={!this.props.roles.admin}>
                                Administration
                            </Link>
                            <div className="otjs-navbar-border" hidden={!this.state.opened}></div>
                            <Link className={this.getLinkClass('log-out')} name='log-out' onClick={this.props.onLogout}
                                to='/'>
                                Log out
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="toolsPanel"><ToolsPanel roles={this.props.roles} apercu={true} /></div>
                {this.state.currentTabSelect === null ? <Redirect to='/padimedical-content' /> : null}
                <AnimatedSwitch opened={this.state.opened} />


                <Footer />

            </div>
        )
    }

}

const AnimatedSwitch = withRouter(({ location, ...props }) => (
    <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} unmountOnExit classNames={"alert"}>
            <div id={"main"} className={"main"}>
                <Switch location={location}>
                    <Route exact path='/padimedical-content' component={ContentRootPanel} />
                    <Route exact path='/import' component={ImportRootPanel} />
                    <Route exact path='/query' component={Query} />
                    <Route exact path='/auto-query' component={AutoQueryRoot} />
                    <Route exact path='/administration' component={AdminRootPanel} />
                    <Route exact path='/robot/:id' render={(props) => <RobotView id={props.match.params.id} />} />
                    <Route exact path='/export' component={ExportPanel} />
                    <Route exact path='/anonymize' component={AnonRootPanel} />
                    <Route exact path='/cd-burner' component={CDBurner} />
                    <Route exact path='/mydicom' component={MyDicom} />
                    <Route exact path='/create-report' component={CreateReportView} />
                    <Route exact path='/request-report-list' component={RequestReportList} />
                    <Route exact path='/advance-report-imagin' component={RequestAdvanceImaginList} />
                    <Route exact path='/delete' component={Delete} />
                    <Route exact path='/dicom-router' component={DicomRouterPanel} />
                    <Route exact path='/report/create/:id' component={CreateReport} />
                    <Route exact path='/report/view/:id' component={ViewReport} />
                    <Route exact path='/report/request/:id' component={RequestReport} />
                    <Route exact path='/report/request-advance-imagin/:id' component={RequestAdvanceImagin} />
                    <Route exact path='/report/addendun/:id' component={Addendun} />
                    <Route exact path='/my-case-list' component={DoctorCaseList} />
                    <Route exact path='/admin-case-list' component={AdminCaseList} />
                </Switch>
            </div>
        </CSSTransition>
    </TransitionGroup>
))
