import { useEffect, useState } from "react";
import apis from "../../services/apis";
import { Modal, Row, Col, Form, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdminCaseListTable from "./AdminCaseListTable";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const saveLocalData = (seachData, key, value) => {
    localStorage.setItem('previous_admin_data', JSON.stringify(seachData));
    localStorage.setItem('previous_admin_key', key);
    localStorage.setItem('previous_admin_value', value);
}

const deleteLocalData = () => {
    localStorage.removeItem('previous_admin_data');
    localStorage.removeItem('previous_admin_key');
    localStorage.removeItem('previous_admin_value');
}

const ExportExcel = (data) => {
    return (
        <ExcelFile element={<button style={{ width: 'max-content' }} className='otjs-button otjs-button-green'>Export/Download</button>}>
            <ExcelSheet data={data.data} name="Employees">
                <ExcelColumn label="Patient Name" value="patient_name" />
                <ExcelColumn label="Patient ID" value="patient_id" />
                <ExcelColumn label="Accesion" value="accessor" />
                <ExcelColumn label="Study Type" value={"study_type"} />
                <ExcelColumn label="Study Date" value={"study_date"} />
                <ExcelColumn label="Status" value={(col) => col.addendumby === undefined ? "Not Finalize" : "Finalize"} />
                <ExcelColumn label="Doctor Incharge" value={(col) => col.doctors.join(" , ")} />
            </ExcelSheet>
        </ExcelFile>
    )
}

const SearchForm = ({ setReports }) => {
    const [data, setData] = useState({ key: null, value: null })

    useEffect(() => {

        let data = localStorage.getItem('previous_admin_data')
        if (data) {
            let keyLocal = localStorage.getItem('previous_admin_key')
            let valueLocal = localStorage.getItem('previous_admin_value')
            setReports(JSON.parse(data));
            setData({ key: keyLocal, value: valueLocal })
        }
    }, [])


    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.key == 'study_date') {
            data.value = data.value.replaceAll('-', '');
        }
        apis.caseList.searchCaseList(data.key, data.value)
            .then(res => {
                saveLocalData(res, data.key, data.value);
                setReports(res);
            })
            .catch(err => console.log(err))
    }
    return (<div onSubmit={(e) => handleSubmit(e)} className="d-flex col-5">
        <div className="d-flex col-8">
            <select className="form-control" name="key" onChange={handleChange} required>
                <option hidden value=''>Select Search Field</option>
                <option selected={data.key === 'patient_id'} value='patient_id'>Patient ID</option>
                <option selected={data.key === 'patient_name'} value='patient_name'>Patient Name</option>
                <option selected={data.key === 'study_type'} value='study_type'>Study Type</option>
                <option selected={data.key === 'doctors'} value='doctors'>Doctor</option>
                <option selected={data.key === 'study_date'} value='study_date'>Study Date</option>
            </select>
            <input type={data.key == 'study_date' ? 'date' : 'text'} required className="form-control ms-1" value={data.value} name="value" onChange={handleChange} />
        </div>
        <button onClick={handleSubmit} className="btn otjs-button otjs-button-blue p-1">Search</button>
    </div>)
}

const AdminCaseList = () => {
    const [reports, setReports] = useState([]);
    useEffect(() => {
        if (!localStorage.getItem('previous_admin_value'))
            fetchData();
    }, [])

    const fetchData = () => {
        apis.caseList.getAdmin()
            .then(res => setReports(res))
    }


    return (<>
        <div className="d-flex">
            <SearchForm setReports={setReports} />
            <button style={{ maxWidth: 150, marginLeft: 'auto' }} onClick={() => { fetchData(); deleteLocalData() }} className="btn otjs-button otjs-button-orange p-2">Refresh</button>
            <div style={{ maxWidth: 150, marginLeft: 20 }}>
                <ExportExcel key={Math.random()} data={reports} />
            </div>
        </div>
        <br />
        <AdminCaseListTable reports={reports} />
    </>);
}

export default AdminCaseList;